<template>
  <div>
    <div v-if="displayForm == true">
      <note-form
        :creation="true"
        :display.sync="displayForm"
        :model-name="modelName"
        :model-id="modelId"
        @created="init()"
      />
      <hr />
    </div>
    <p v-if="notes.length == 0" class="text-center text-muted">
      <em> Aucune note pour l’instant </em>
    </p>
    <div v-for="note in notes" :key="note.id" class="row">
      <div class="col-md-11">
        <note-form
          v-if="note.edit == true"
          :display.sync="note.edit"
          :model-name="modelName"
          :model-id="modelId"
          :note-id="note.id"
          :note="note"
          @updated="init()"
        />
        <div v-else>
          <div class="p-2 border">
            <markdown-it-vue v-if="note.content" :content="note.content" />
          </div>
          <p class="text-right text-muted">
            Mise à jour le
            {{ $dayjs(note.updated_at).format('DD-MM-YYYY à HH:mm') }}
          </p>
        </div>
      </div>
      <div class="col-md-1 text-right">
        <button
          class="btn btn-info btn-sm"
          @click.prevent="note.edit = !note.edit"
        >
          <font-awesome-icon icon="pencil-alt" />
        </button>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
    </div>
    <!-- <pre>{{ notes }}</pre> -->
  </div>
</template>
<script>
import NoteForm from '@/components/NoteForm.vue'

export default {
  name: 'Notes',
  components: {
    NoteForm,
  },
  props: {
    displayForm: {
      type: Boolean,
      default: false,
    },
    modelName: {
      type: String,
      default: null,
      required: true,
    },
    modelId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      notes: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/notes/`, {
        params: {
          status: 'PUBLIC',
          model_name: this.modelName,
          model_id: this.modelId,
        },
        headers: {
          'X-Fields': '*',
        },
      })
      this.notes = resp.data.results
      this.notes = this.notes.map((i) => ({ ...i, edit: false }))
    },
  },
}
</script>
