<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form :label-position="'left'" @submit.prevent>
        <template v-if="creation == true || userId">
          <b-form-group label-for="input-status">
            <b-form-select v-model="form.status" placeholder="Statut">
              <b-form-select-option
                v-for="option in ['ENABLED', 'DISABLED']"
                :key="option"
                :value="option"
              >
                {{ $t(option) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="">
            <span class="pl-1 pr-1"> Client Pro </span>
            <el-switch
              v-model="form.is_business_customer"
              active-text=""
              inactive-text=""
            >
            </el-switch>
          </b-form-group>
          <b-form-group label-for="input-role">
            <b-form-select v-model="form.role" placeholder="Role">
              <b-form-select-option
                v-for="option in ['GUEST', 'CUSTOMER']"
                :key="option"
                :value="option"
              >
                {{ $t(`ROLES.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="input-firstname">
            <validation-provider
              #default="{ errors }"
              name="Prénom"
              rules="required"
            >
              <b-form-input
                id="input-firstname"
                v-model="form.firstname"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Prénom"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-lastname">
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="input-lastname"
                v-model="form.lastname"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Nom"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-entity">
            <b-form-input
              id="input-entity"
              v-model="form.entity"
              type="text"
              placeholder="Entreprise, association…"
            />
          </b-form-group>
          <b-form-group label-for="input-siret">
            <b-form-input
              id="input-siret"
              v-model="form.siret"
              type="text"
              placeholder="N° siret"
            />
          </b-form-group>
          <b-form-group label-for="input-tax_number">
            <b-form-input
              id="input-tax_number"
              v-model="form.tax_number"
              type="text"
              placeholder="N° de TVA"
            />
          </b-form-group>
          <b-form-group label-for="input-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-input
                id="input-email"
                v-model="form.email"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-phone">
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              type="text"
              placeholder="Téléphone"
            />
          </b-form-group>
        </template>
        <template v-if="userId">
          <b-form-group label="">
            <b-form-checkbox-group
              v-model="form.prefered_contact_channels"
              :options="[
                { text: 'Email', value: 'EMAIL' },
                { text: 'SMS', value: 'TEXT_MESSAGE' },
              ]"
              name="prefered_contact_channels"
            />
          </b-form-group>
        </template>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right d-none">
            <b-button v-if="userId" v-b-modal.modal-delete variant="danger">
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe"
      centered
      hide-header-close
      title="Suprimmer la recette"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteUser()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ form }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormCheckboxGroup,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'UserForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckboxGroup,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    userId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      form: {
        status: null,
        is_business_customer: false,
        siret: null,
        tax_number: null,
        entity: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        role: null,
        prefered_contact_channels: null,
      },
    }
  },
  watch: {
    userId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.userId) {
        const resp = await this.$http.get(`/users/${this.userId}/`, {
          headers: {
            'X-Fields': `${Object.keys(this.form).join(',')}`,
          },
        })
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        console.log(success)
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.userId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/users/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(`/users/${this.userId}/`, this.form, {
          headers: {
            'X-Fields': 'id',
            'X-Keys': Object.keys(this.form).join(','),
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        })
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async duplicateUser() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/users/${this.userId}/duplicate/`,
        {},
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('duplicated', resp.data)
    },
    async deleteUser() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(`/users/${this.userId}/`, {
        headers: {
          'X-Fields': 'id',
          'X-Keys': 'id',
          'X-CSRF-TOKEN': respCsrf.data.csrf,
        },
      })
      this.$emit('deleted', resp.data)
      this.$refs['delete-recipe'].hide()
    },
  },
}
</script>
