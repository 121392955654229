var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',{attrs:{"label-position":'left'},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.creation == true || _vm.userId)?[_c('b-form-group',{attrs:{"label-for":"input-status"}},[_c('b-form-select',{attrs:{"placeholder":"Statut"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((['ENABLED', 'DISABLED']),function(option){return _c('b-form-select-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(_vm.$t(option))+" ")])}),1)],1),_c('b-form-group',{attrs:{"label":""}},[_c('span',{staticClass:"pl-1 pr-1"},[_vm._v(" Client Pro ")]),_c('el-switch',{attrs:{"active-text":"","inactive-text":""},model:{value:(_vm.form.is_business_customer),callback:function ($$v) {_vm.$set(_vm.form, "is_business_customer", $$v)},expression:"form.is_business_customer"}})],1),_c('b-form-group',{attrs:{"label-for":"input-role"}},[_c('b-form-select',{attrs:{"placeholder":"Role"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},_vm._l((['GUEST', 'CUSTOMER']),function(option){return _c('b-form-select-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(_vm.$t(("ROLES." + option)))+" ")])}),1)],1),_c('b-form-group',{attrs:{"label-for":"input-firstname"}},[_c('validation-provider',{attrs:{"name":"Prénom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-firstname","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Prénom"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1655523487)})],1),_c('b-form-group',{attrs:{"label-for":"input-lastname"}},[_c('validation-provider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-lastname","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4239392052)})],1),_c('b-form-group',{attrs:{"label-for":"input-entity"}},[_c('b-form-input',{attrs:{"id":"input-entity","type":"text","placeholder":"Entreprise, association…"},model:{value:(_vm.form.entity),callback:function ($$v) {_vm.$set(_vm.form, "entity", $$v)},expression:"form.entity"}})],1),_c('b-form-group',{attrs:{"label-for":"input-siret"}},[_c('b-form-input',{attrs:{"id":"input-siret","type":"text","placeholder":"N° siret"},model:{value:(_vm.form.siret),callback:function ($$v) {_vm.$set(_vm.form, "siret", $$v)},expression:"form.siret"}})],1),_c('b-form-group',{attrs:{"label-for":"input-tax_number"}},[_c('b-form-input',{attrs:{"id":"input-tax_number","type":"text","placeholder":"N° de TVA"},model:{value:(_vm.form.tax_number),callback:function ($$v) {_vm.$set(_vm.form, "tax_number", $$v)},expression:"form.tax_number"}})],1),_c('b-form-group',{attrs:{"label-for":"input-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-email","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1973566676)})],1),_c('b-form-group',{attrs:{"label-for":"input-phone"}},[_c('b-form-input',{attrs:{"id":"input-phone","type":"text","placeholder":"Téléphone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)]:_vm._e(),(_vm.userId)?[_c('b-form-group',{attrs:{"label":""}},[_c('b-form-checkbox-group',{attrs:{"options":[
              { text: 'Email', value: 'EMAIL' },
              { text: 'SMS', value: 'TEXT_MESSAGE' } ],"name":"prefered_contact_channels"},model:{value:(_vm.form.prefered_contact_channels),callback:function ($$v) {_vm.$set(_vm.form, "prefered_contact_channels", $$v)},expression:"form.prefered_contact_channels"}})],1)]:_vm._e(),_c('hr'),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Valider ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"light"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('update:display', false)}}},[_vm._v(" Annuler ")])],1),_c('div',{staticClass:"col-md-6 text-right d-none"},[(_vm.userId)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-delete",modifiers:{"modal-delete":true}}],attrs:{"variant":"danger"}},[_vm._v(" Supprimer ")]):_vm._e()],1)])],2)],1),_c('b-modal',{ref:"delete-recipe",attrs:{"id":"modal-delete","centered":"","hide-header-close":"","title":"Suprimmer la recette"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
            var ok = ref.ok;
            var cancel = ref.cancel;
            var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v(" Confirmer ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return hide('forget')}}},[_vm._v(" Annuler ")])]}}])},[_c('p',{staticClass:"my-2"},[_vm._v("Confirmez-vous la suppression de cet élément ?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }