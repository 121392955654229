<template>
  <dl class="row">
    <dt class="col-sm-3 text-right text-muted">#</dt>
    <dd class="col-sm-9">
      <b><copy-value :value="user.id" /></b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Statut</dt>
    <dd class="col-sm-9">
      <b>{{ $t(user.status) }}</b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Email</dt>
    <dd class="col-sm-9">
      <b><copy-value :value="user.email" /></b>
      <a :href="`mailto:${user.email}`">
        <feather-icon class="mr-1" icon="SendIcon" />
      </a>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Téléphone</dt>
    <dd class="col-sm-9">
      <b>{{ user.phone || '-' }}</b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Allergies</dt>
    <dd class="col-sm-9">
      <b v-if="user.diet">{{ user.allergies.join(', ') }}</b>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Régime</dt>
    <dd class="col-sm-9">
      <b v-if="user.diet">{{ $t(user.diet) }}</b>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Activitée sportive</dt>
    <dd class="col-sm-9">
      <b v-if="user.diet">{{ $t(user.physical_exercise) }}</b>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">N° siret</dt>
    <dd class="col-sm-9">
      <b v-if="user.diet">{{ user.siret }}</b>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">N° de TVA</dt>
    <dd class="col-sm-9">
      <b v-if="user.diet">{{ user.tax_number }}</b>
      <span v-else>-</span>
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    user: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
