<template>
  <div>
    <b-form @submit.prevent>
      <b-form-group label-for="input-content">
        <b-form-textarea
          id="input-content"
          v-model="form.content"
          type="text"
          rows="4"
          max-rows="6"
          placeholder="Votre texte ici"
        />
      </b-form-group>
      <div class="row">
        <div class="col-md-6">
          <b-button
            variant="primary"
            class="mr-1"
            @click.prevent="validationForm"
          >
            <span v-if="noteId">Mettre à jour</span>
            <span v-else>Ajouter</span>
          </b-button>
          <b-button
            v-if="creation == true"
            variant="light"
            class="mr-1"
            @click.prevent="$emit('update:display', false)"
          >
            Annuler
          </b-button>
        </div>
        <div class="col-md-6 text-right">
          Ce champ est compatible avec la syntaxe
          <a
            href="https://guides.github.com/features/mastering-markdown/"
            target="_blank"
          >
            markdown.
          </a>
        </div>
      </div>
    </b-form>
    <!-- <pre>{{ form }}</pre> -->
  </div>
</template>
<script>
import { required } from '@validations'
import { BForm, BFormGroup, BButton, BFormTextarea } from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormTextarea,
    BButton,
  },
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    modelName: {
      type: String,
      default: null,
      required: true,
    },
    modelId: {
      type: String,
      default: null,
      required: true,
    },
    noteId: {
      type: String,
      default: null,
    },
    note: {
      type: Object,
      default() {
        return {}
      },
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: null,
      loading: false,
      form: {
        content: 'foo bar',
        model_name: null,
        model_id: null,
      },
    }
  },
  watch: {},
  created() {
    this.init()
  },
  methods: {
    init() {
      this.form.model_name = this.modelName
      this.form.model_id = this.modelId
      if (this.note) {
        this.form.content = this.note.content
      }
    },
    validationForm() {
      if (this.creation === true) {
        this.create()
      } else if (this.noteId) {
        this.update()
      }
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post('/notes/', this.form, {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        })
        this.$emit('created', resp.data)
        this.$emit('update:display', false)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(`/notes/${this.noteId}/`, this.form, {
          headers: {
            'X-Fields': 'id',
            'X-UpdateKeys': 'content',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        })
        this.$emit('updated', resp.data)
        this.$emit('update:display', false)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
<style scoped>
.helper {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 1rem;
}
</style>
