<template>
  <div>
    <b-card v-if="user.id">
      <div class="row">
        <div class="col-lg-8">
          <h3>
            {{ $t(`ROLES.${user.role}`) }} / {{ user.fullname }}
            <span
              v-if="user.is_business_customer === true"
              class="badge badge-dark"
            >
              PRO
            </span>
            <br />
            <small>
              Créé le
              {{ $dayjs(user.created_at).format('DD-MM-YYYY') }}
              - Mise à jour le
              {{ $dayjs(user.updated_at).format('DD-MM-YYYY à HH:mm') }}
            </small>
          </h3>
        </div>
        <div class="col-lg-4 text-right">
          <button
            class="btn btn-info"
            @click.prevent="displayAccessUrl = !displayAccessUrl"
          >
            Se connecter comme
          </button>
        </div>
      </div>
      <div v-if="displayAccessUrl" class="border m-3 p-3">
        <div class="p-l-20 p-r-20">
          <h2 class="h3">Obtenir une URL d’accès simplifié au compte</h2>
          <p>
            Cette fonctionnalité est destinée aux personnes qui ne reçoivent pas
            les messages de notification de changement de mot de passe ou qui
            rencontre des difficultés avec cette fonctionnalité.
            <b>Cette URL est valable 48h</b> et doit être envoyé à une personne
            bien identifiée, de préférence à l'adresse email associée à ce
            compte.
          </p>
          <button class="btn btn-sm btn-danger" @click.prevent="getAccessUrl()">
            Générer une URL pour cet utilisateur
          </button>
          <hr />
          <div v-if="accessUrl">
            <p>
              <copy-value :value="accessUrl" />
            </p>
            <p>
              <a :href="accessUrl" target="_blank" class="btn btn-sm btn-info">
                Se connecter comme
              </a>
            </p>
          </div>
        </div>
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <div class="row">
            <div class="col-md-11">
              <user-card v-if="displayUserForm == false" :user="user" />
              <user-form
                v-else
                :user-id="user.id"
                :display.sync="displayUserForm"
                class="mb-4"
                @updated="init()"
              />
            </div>
            <div class="col-md-1">
              <button
                class="btn btn-info btn-sm"
                @click="displayUserForm = !displayUserForm"
              >
                <font-awesome-icon icon="pencil-alt" />
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-11">
              <dl class="row">
                <dt class="col-sm-3 text-right text-muted">
                  Adresse de facturation
                </dt>
                <dd class="col-sm-9">
                  <user-address-card
                    v-if="displayBillingAddressForm == false"
                    :address="user.address_billing"
                    type="BILLING"
                  />
                  <div v-else class="mb-4">
                    <user-adress-form
                      :user-address-id="user.address_billing.id"
                      :display.sync="displayBillingAddressForm"
                      scheme="BILLING"
                      @updated="init"
                    />
                  </div>
                </dd>
              </dl>
            </div>
            <div class="col-md-1">
              <button
                class="btn btn-info btn-sm"
                @click="displayBillingAddressForm = !displayBillingAddressForm"
              >
                <font-awesome-icon icon="pencil-alt" />
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-11">
              <dl class="row">
                <dt class="col-sm-3 text-right text-muted">
                  Adresse de livraison
                </dt>
                <dd class="col-sm-9">
                  <user-address-card
                    v-if="displayDeliveryAddressForm == false"
                    :address="user.address_delivery"
                    type="DELIVERY"
                  />
                  <div v-else class="mb-4">
                    <user-adress-form
                      :user-address-id="user.address_delivery.id"
                      :display.sync="displayDeliveryAddressForm"
                      scheme="DELIVERY"
                      @updated="init"
                    />
                  </div>
                </dd>
              </dl>
            </div>
            <div class="col-md-1">
              <button
                class="btn btn-info btn-sm"
                @click="
                  displayDeliveryAddressForm = !displayDeliveryAddressForm
                "
              >
                <font-awesome-icon icon="pencil-alt" />
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Notifications">
          <user-messages :user-email="user.email" :user-id="user.id" />
        </b-tab>
        <b-tab title="Commandes">
          <table class="table">
            <tbody>
              <template v-for="order in user.orders_history">
                <tr :key="order.id">
                  <td>
                    <router-link
                      :to="{ name: 'order', params: { id: order.id } }"
                    >
                      {{ order.public_reference }}
                    </router-link>
                  </td>
                  <td>
                    {{ $dayjs(order.created_at).format('DD-MM-YYYY') }}
                  </td>
                  <td>{{ $t(`ORDER.${order.status}`) }}</td>
                  <td class="text-right">{{ order.grand_total }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Transactions">
          <table class="table table-hover table-sm">
            <tbody>
              <tr v-for="txn in user.orders_transactions" :key="txn.id">
                <td>
                  {{ $dayjs(txn.transaction_at).format('DD-MM-YYYY HH:mm') }}
                </td>
                <td>
                  {{ txn.payment_service }}
                </td>
                <td>
                  {{ txn.transaction_type }}
                </td>
                <td :title="txn.status">
                  {{ txn.transaction_status }}
                </td>
                <td>
                  <b :title="txn.transaction_id">
                    <copy-value
                      :value="txn.transaction_id"
                      :display="`#${txn.transaction_id.slice(0, 8)}…`"
                    />
                  </b>
                </td>
                <td>
                  {{ txn.amount | currency }}
                </td>
                <td>
                  <router-link
                    v-if="txn.object_type == 'ORDER'"
                    :to="`/orders/${txn.object_id}`"
                  >
                    Commande
                  </router-link>
                  <router-link
                    v-if="txn.object_type == 'USER_SUBSCRIPTION'"
                    :to="`/orders/${txn.object_id}`"
                  >
                    Abonnement
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <pre>{{ user.orders_transactions }}</pre> -->
        </b-tab>
        <b-tab title="Historique">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Le</th>
                <th>Action</th>
                <th>Type de données</th>
                <th class="text-right">Données</th>
              </tr>
            </thead>
            <tbody v-for="log in user.last_logs_entries" :key="log.id">
              <tr>
                <td>
                  {{ $dayjs(log.created_at).format('DD-MM-YYYY HH:mm') }}
                </td>
                <td>
                  {{ log.event }}
                </td>
                <td>
                  <router-link
                    v-if="log.object_type == 'ORDER'"
                    :to="`/orders/${log.object_id}`"
                  >
                    Commande
                  </router-link>
                  <router-link
                    v-else-if="log.object_type == 'USER_SUBSCRIPTION'"
                    :to="`/orders/${log.object_id}`"
                  >
                    Abonnement
                  </router-link>
                  <span v-else>
                    {{ log.object_type }}
                  </span>
                </td>
                <td class="text-right">
                  <button
                    class="btn btn-info btn-sm"
                    @click.prevent="log.display = !log.display"
                  >
                    <font-awesome-icon icon="eye" class="fa-fw" />
                  </button>
                </td>
              </tr>
              <tr v-if="log.display == true">
                <td colspan="4">
                  <pre>{{ log.properties }}</pre>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ user }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <h3>Abonnements</h3>
      <p
        v-if="
          user.subscriptions_public && user.subscriptions_public.length == 0
        "
        class="text-center text-muted"
      >
        <em> Pas d‘abonnement pour l‘instant </em>
      </p>
      <div class="row mb-2">
        <div
          v-for="subscription in user.subscriptions_public"
          :key="subscription.id"
          class="col-lg-6 pt-2"
        >
          <user-subscription-card
            :subscription="subscription"
            :small-card="true"
            :link-active="true"
          />
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="row">
        <div class="col-md-11">
          <h3>Notes</h3>
        </div>
        <div class="col-md-1 text-right py-0">
          <button
            class="btn btn-info btn-sm"
            @click.prevent="diplayNotesForm = !diplayNotesForm"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </div>
      </div>
      <hr />
      <notes
        model-name="USER"
        :display-form.sync="diplayNotesForm"
        :model-id="$route.params.id"
      />
    </b-card>
    <!-- <pre>{{ user }}</pre> -->
  </div>
</template>

<script>
import Notes from '@/components/Notes.vue'
import UserCard from '@/components/UserCard.vue'
import UserForm from '@/components/UserForm.vue'
import UserAdressForm from '@/components/UserAdressForm.vue'
import UserAddressCard from '@/components/UserAddressCard.vue'
import UserSubscriptionCard from '@/components/UserSubscriptionCard.vue'
import UserMessages from '@/components/UserMessages.vue'
import { BCard, BTabs, BTab } from 'bootstrap-vue'

const userFields =
  '*,orders_transactions,subscriptions_public,' +
  'orders_history{id,public_reference,status,grand_total,created_at,updated_at}'

export default {
  components: {
    Notes,
    UserCard,
    UserForm,
    UserAddressCard,
    UserAdressForm,
    UserSubscriptionCard,
    BTabs,
    BTab,
    BCard,
    UserMessages,
  },
  data() {
    return {
      diplayNotesForm: false,
      displayUserForm: false,
      displayBillingAddressForm: false,
      displayDeliveryAddressForm: false,
      displayAccessUrl: false,
      accessUrl: null,
      user: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/users/${this.$route.params.id}/`, {
        headers: {
          'X-Fields': userFields,
        },
      })
      this.user = resp.data
    },
    async getAccessUrl() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        await this.$http
          .post(
            `/users/${this.$route.params.id}/access-url/`,
            {},
            {
              headers: {
                'X-Fields': 'id',
                'X-CSRF-TOKEN': respCsrf.data.csrf,
              },
            }
          )
          .then((response) => {
            console.log(response.data)
            // this.accessUrl = response.data.access_url
            this.accessUrl = response.data.access_url.replace(
              'frontend.leonetmarcel.docker',
              'localhost:3000'
            )
          })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
