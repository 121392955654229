<template>
  <div class="">
    <table class="table table-compact">
      <tbody>
        <tr>
          <th>Statut</th>
          <th>Envoyé le</th>
          <th>Objet</th>
          <th></th>
        </tr>
      </tbody>
      <tbody>
        <tr v-for="message in messages" :key="message.id">
          <td>
            {{ $t(`message.${message.status}`) }}
          </td>
          <td>
            <span v-if="message.status == 'CREATED'">
              <button
                class="btn btn-danger btn-sm"
                @click.prevent="sendWelcomeMessage()"
              >
                Envoyer le message
              </button>
            </span>
            <span>
              {{ $dayjs(message.send_at).format('DD-MM-YYYY') }}
            </span>
          </td>
          <td>
            {{ message.message_template.subject }}
          </td>
          <td class="text-right">
            <a
              :href="message.preview_url"
              target="_blank"
              class="btn btn-info btn-sm"
            >
              <font-awesome-icon icon="eye" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <pre>{{ messages }}</pre> -->
    <!-- <pre>{{ userEmail }}</pre> -->
  </div>
</template>
<script>
const fields =
  'results{id,send_at,status,preview_url,message_template{subject}}'
export default {
  name: 'UserMessages',
  props: {
    userEmail: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      messages: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.$http.get(`/messages/`, {
        params: {
          per_page: 100,
          recipient_email: this.userEmail,
          user_id: this.userId,
        },
        headers: { 'X-Fields': fields },
      })
      this.messages = response.data.results
    },
  },
}
</script>
