<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <template v-if="creation == true || userAddressId">
          <b-form-group label-for="input-firstname">
            <b-form-input
              id="input-firstname"
              v-model="form.firstname"
              type="text"
              placeholder="Prénom"
            />
          </b-form-group>
          <b-form-group label-for="input-lastname">
            <b-form-input
              id="input-lastname"
              v-model="form.lastname"
              type="text"
              placeholder="Nom"
            />
          </b-form-group>
          <b-form-group label-for="input-entity">
            <b-form-input
              id="input-entity"
              v-model="form.entity"
              type="text"
              placeholder="Entreprise, association…"
            />
          </b-form-group>
          <b-form-group label-for="input-email">
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="text"
              placeholder="Email"
            />
          </b-form-group>
          <b-form-group label-for="input-phone">
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              type="text"
              placeholder="Téléphone"
            />
          </b-form-group>
          <b-form-group label-for="input-street_pre">
            <b-form-input
              id="input-street_pre"
              v-model="form.street_pre"
              type="text"
              placeholder="Étage, code porte…"
            />
          </b-form-group>
          <b-form-group label-for="input-street">
            <validation-provider
              #default="{ errors }"
              name="Rue"
              rules="required"
            >
              <b-form-input
                id="input-street"
                v-model="form.street"
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="Rue"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-informations">
            <b-form-input
              id="input-informations"
              v-model="form.informations"
              type="text"
              placeholder="Complément d’adresse"
            />
          </b-form-group>
          <b-form-group label-for="input-postal_code">
            <validation-provider
              #default="{ errors }"
              name="Ville"
              rules="required"
            >
              <b-form-input
                id="input-postal_code"
                v-model="form.postal_code"
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="Code postal"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-locality">
            <validation-provider
              #default="{ errors }"
              name="Ville"
              rules="required"
            >
              <b-form-input
                id="input-locality"
                v-model="form.locality"
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="Ville"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-country_name">
            <validation-provider
              #default="{ errors }"
              name="Pays"
              rules="required"
            >
              <b-form-input
                id="input-country_name"
                v-model="form.country_name"
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="Pays"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right d-none"></div>
        </div>
      </b-form>
    </validation-observer>
    <!-- <pre>{{ form }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'

export default {
  name: 'UserAdressForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    userAddressId: {
      default: null,
      type: String,
    },
    scheme: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      form: {
        scheme: null,
        entity: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        informations: null,
        street_pre: null,
        street: null,
        postal_code: null,
        locality: null,
        country_name: null,
      },
    }
  },
  watch: {
    userAddressId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.userAddressId) {
        const resp = await this.$http.get(
          `/users/addresses/${this.userAddressId}/`,
          {
            headers: {
              'X-Fields': `${Object.keys(this.form).join(',')}`,
            },
          }
        )
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.userAddressId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/users/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/users/addresses/${this.userAddressId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$emit('update:display', false)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
