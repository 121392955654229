<template>
  <div>
    <span class="text-muted">
      #{{ address.public_reference }}
      <br />
    </span>
    <template v-if="address && address.street">
      <span v-if="address.entity">
        {{ address.entity }}
        <br />
      </span>
      <span v-if="address.firstname || address.lastname">
        {{ address.firstname }}
        {{ address.lastname }}
        <br />
      </span>
      <span v-if="address.informations">
        {{ address.informations }}
        <br />
      </span>
      <span v-if="address.street_pre || address.street">
        <span v-if="address.street_pre"> {{ address.street_pre }} <br /> </span>
        <span v-if="address.street">
          <b>{{ address.street }}</b>
        </span>
        <br />
      </span>
      <span
        v-if="address.postal_code || address.locality || address.country_name"
      >
        <span v-if="address.postal_code"> {{ address.postal_code }} </span>
        <span v-if="address.locality"> {{ address.locality }} </span>
        <span v-if="address.country_name">
          {{ address.country_name }}
        </span>
        <br />
      </span>
      <span v-if="address.email">
        Email : <b><copy-value :value="address.email" /></b> <br />
      </span>
      <span v-if="address.phone"> Tél. : {{ address.phone }} <br /> </span>
    </template>
    <template v-else>
      <dd><em>Aucune adresse</em></dd>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UserAddressCard',
  props: {
    title: {
      type: String,
      default: 'Adresse',
    },
    type: {
      type: String,
      default: 'BILLING',
    },
    address: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
